// Vendors
require('objectFitPolyfill/dist/objectFitPolyfill.basic.min')
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills'
import 'unpoly/dist/unpoly'

up.compiler('body', (element) => {
    $('html').removeClass('no-js');
    new SmoothScroll('a[href*="#"]');

    const $body = element;
    let scrollPosition = 0;

    window.disableScroll = () => {
        scrollPosition = window.pageYOffset;
        $body.style.overflow = 'scroll';
        $body.style.position = 'fixed';
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';
    };

    window.enableScroll = () => {
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
    };

    objectFitPolyfill();
});

up.compiler('[data-toggle="offcanvas"]', (element) => {
    let $contentWrapper = $('.content-wrapper');
    let $body = $('body');

    $(element).on('click.offcanvas', () => {
        if ($contentWrapper.hasClass('menu-visible')) {
            closeOffCanvas();
            $(element).attr('aria-expanded', 'false').addClass('collapsed');
        } else {
            openOffCanvas();
            $(element).attr('aria-expanded', 'true').removeClass('collapsed');
        }
    });

    let openOffCanvas = () => {
        $contentWrapper.addClass('menu-visible');
        disableScroll();

        setTimeout(() => {
            $('body').on('click.offcanvas', function (event) {
                if ($(event.target).is('.main')) {
                    closeOffCanvas();
                }
            });
        }, 300)
    };

    let closeOffCanvas = () => {
        $contentWrapper.removeClass('menu-visible');
        $body.off('click.offcanvas');
        enableScroll();
    };

    return () => {
        $(element).off('click.offcanvas');
    };
});

up.compiler('.js-toggle', (element) => {
    $(element).parent().parent().removeClass('expanded');

    $(element).on('click', ($event) => {
        $event.preventDefault();
        $(element).parent().parent().toggleClass('expanded');
    });

    return () => {
        $(element).off('click');
    }
});
